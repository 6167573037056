import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import {
    addPackageApi,
    pullPackagesApi,
    pullTransactionsApi,
    purchasePackageApi,
    purchaseServiceApi
} from "../../services";
import {  useNavigate } from 'react-router-dom';
import {description} from "../../_mock/text";

// ----------------------------------------------------------------------


const initialState = {
    isLoadingTransaction: false,
    error: null,
    stats:{pending:0,complete:0, all:0},
    transactions:[{ purchase_id:"sea", amount:1000,registered:'2/3/2020',status:"pending",service:{title:"package"}}],
    msg: null,
};

const slice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoadingTransaction = true;
        },
        // END LOADING
        endLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoadingTransaction = false;
            state.error = action.payload;
        },
        //success
        success(state,action){
            state.isLoadingTransaction = false;
            state.msg = action.payload;
            setTimeout(()=>{state.msg=null},3000)
        },
        //get transactions
        getTransactions (state,action){
            state.transactions =action.payload;
        },

        getStats (state,action){
            state.stats =action.payload;
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    startLoading,
    getTransactions,
    getStats,
    hasError,
    success,
} = slice.actions;

// ----------------------------------------------------------------------

export function  purchaseService(data1) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await purchaseServiceApi(data1);
            if(response.data.code === 200){
                dispatch(slice.actions.success(" successfully"));
                dispatch(slice.actions.endLoading())
                window.location.replace(response.data.url);
                // console.log(response.data)

            }else{
                dispatch(slice.actions.hasError(response.data.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

export function  getTransactions2(userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullTransactionsApi(userId);
            if(response.data.code === 200){
                const purchases =response.data.purchases;
                dispatch(slice.actions.getTransactions(response.data.purchases))
                dispatch(slice.actions.getStats({
                    complete:purchases.reduce((acc, cur) => cur.status === "complete" ? ++acc : acc, 0) ,
                    pending:purchases.reduce((acc, cur) => cur.status === "pending" ? ++acc : acc, 0),
                    all:purchases.length
                }))
                dispatch(slice.actions.success(" successfully"));
                dispatch(slice.actions.endLoading())

            }else{
                dispatch(slice.actions.hasError(response.data.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}