import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
// components
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PageNotFoundIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  background: '#161C24',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  const goToHome = () => {
    window.location.href = 'https://intelligenceforex.com';
  };
  return (
    <Page title="404 Page Not Found" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                If you have payed successfully, check your email
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              We offer all our courses in the mobile app. Download the app from the link below.
            </Typography>

            <m.div variants={varBounce().in}>
              <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </m.div>

            {/* New buttons for iOS and Play Store */}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
              <Button
                variant="contained"
                startIcon={<AppleIcon />}
                href="https://apps.apple.com/tz/app/intelligencefx/id1663934841"
                target="_blank"
              >
                App Store
              </Button>
              <Button
                variant="contained"
                startIcon={<AndroidIcon />}
                href="https://play.google.com/store/apps/details?id=com.intelligencefx.app&pli=1"
                target="_blank"
              >
                Google Play
              </Button>
            </Box>

            {/* <Button size="large" variant="contained" onClick={goToHome}>
              Go to Home
            </Button> */}
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
