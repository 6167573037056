// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

// export const PATH_AUTH = {
//   root: ROOTS_AUTH,
//   login: path(ROOTS_AUTH, '/login'),
//   loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
//   register: path(ROOTS_AUTH, '/register'),
//   register2: path(ROOTS_AUTH, '/register-2'),
//   registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
//   resetPassword: path(ROOTS_AUTH, '/reset-password'),
//   verify: path(ROOTS_AUTH, '/verify'),
// };

// export const PATH_PAGE = {
//   comingSoon: '/coming-soon',
//   maintenance: '/maintenance',
//   pricing: '/pricing',
//   payment: '/payment',
//   about: '/about-us',
//   contact: '/contact-us',
//   faqs: '/faqs',
//   page404: '/404',
//   page500: '/500',
//   components: '/components',
// };

// export const PATH_WEB = {
//   comingSoon: '/coming-soon',
//   maintenance: '/maintenance',
//   pricing: '/pricing',
//   payment: '/payment',
//   about: '/about-us',
//   contact: '/contact-us',
//   faqs: '/faqs',
//   page404: '/404',
//   page500: '/500',
//   components: '/components',
//   posts: '/posts',
//   post: '/post/:title',
//   packagePayment: '/pay-package',
// };

// export const PATH_DASHBOARD = {
//   root: ROOTS_DASHBOARD,
//   general: {
//     app: path(ROOTS_DASHBOARD, '/app'),
//     ecommerce: path(ROOTS_DASHBOARD, '/packages'),
//     analytics: path(ROOTS_DASHBOARD, '/courses'),
//     banking: path(ROOTS_DASHBOARD, '/payment/:packageId'),
//     booking: path(ROOTS_DASHBOARD, '/booking'),
//     free: path(ROOTS_DASHBOARD, '/free-resources'),
//     videos: path(ROOTS_DASHBOARD, '/videos'),
//     freeArticle: path(ROOTS_DASHBOARD, '/free-resources/:title'),
//   },
//   mail: {
//     root: path(ROOTS_DASHBOARD, '/mail'),
//     all: path(ROOTS_DASHBOARD, '/mail/all'),
//   },
//   chat: {
//     root: path(ROOTS_DASHBOARD, '/chat'),
//     new: path(ROOTS_DASHBOARD, '/chat/new'),
//     conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
//   },
//   calendar: path(ROOTS_DASHBOARD, '/calendar'),
//   kanban: path(ROOTS_DASHBOARD, '/kanban'),
//   user: {
//     root: path(ROOTS_DASHBOARD, '/user'),
//     profile: path(ROOTS_DASHBOARD, '/user/profile'),
//     cards: path(ROOTS_DASHBOARD, '/user/cards'),
//     list: path(ROOTS_DASHBOARD, '/user/list'),
//     newUser: path(ROOTS_DASHBOARD, '/user/new'),
//     editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
//     account: path(ROOTS_DASHBOARD, '/user/account'),
//   },
//   eCommerce: {
//     root: path(ROOTS_DASHBOARD, '/e-commerce'),
//     shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
//     product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
//     productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
//     list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
//     newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
//     editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
//     checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
//     invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
//   },
//   blog: {
//     root: path(ROOTS_DASHBOARD, '/blog'),
//     posts: path(ROOTS_DASHBOARD, '/blog/posts'),
//     post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
//     postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
//     newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
//   },
// };

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: '/404',
  register: path(ROOTS_AUTH, '/register'),
  register2: '/404',
  registerUnprotected: '/404',
  resetPassword: '/404',
  verify: '/404',
};

export const PATH_PAGE = {
  comingSoon: '/404',
  maintenance: '/404',
  pricing: '/404',
  payment: '/404',
  about: '/404',
  contact: '/404',
  faqs: '/404',
  page404: '/404',
  page500: '/404',
  components: '/404',
};

export const PATH_WEB = {
  comingSoon: '/404',
  maintenance: '/404',
  pricing: '/404',
  payment: '/404',
  about: '/404',
  contact: '/404',
  faqs: '/404',
  page404: '/404',
  page500: '/404',
  components: '/404',
  posts: '/404',
  post: '/404',
  packagePayment: '/pay-package',
};

export const PATH_DASHBOARD = {
  root: '/404',
  general: {
    app: '/404',
    ecommerce: '/404',
    analytics: '/404',
    banking: '/404',
    booking: '/404',
    free: '/404',
    videos: '/404',
    freeArticle: '/404',
  },
  mail: {
    root: '/404',
    all: '/404',
  },
  chat: {
    root: '/404',
    new: '/404',
    conversation: '/404',
  },
  calendar: '/404',
  kanban: '/404',
  user: {
    root: '/404',
    profile: '/404',
    cards: '/404',
    list: '/404',
    newUser: '/404',
    editById: '/404',
    account: '/404',
  },
  eCommerce: {
    root: '/404',
    shop: '/404',
    product: '/404',
    productById: '/404',
    list: '/404',
    newProduct: '/404',
    editById: '/404',
    checkout: '/404',
    invoice: '/404',
  },
  blog: {
    root: '/404',
    posts: '/404',
    post: '/404',
    postById: '/404',
    newPost: '/404',
  },
};

export const PATH_DOCS = '/404';
