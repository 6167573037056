import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import {addPackageApi, pullPackagesApi, pullPackagesFreeApi} from "../../services";
import {  useNavigate } from 'react-router-dom';
import {description} from "../../_mock/text";

// ----------------------------------------------------------------------


const initialState = {
    isLoading: false,
    error: null,
    msg: null,
    packages:[],
    signalPackages:[],
    trainingPackages:[],
    selectedPackage:{service_id:"", type:"",price:"0",description:"",title:""}
};

const slice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // END LOADING
        endLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        //success
        success(state,action){
            state.isLoading = false;
            state.msg = action.payload;
            setTimeout(()=>{state.msg=null},1000)
        },

        // PULL PACKAGES
        getPackages(state, action) {
            state.packages = action.payload;
            // state.selectedPackage ={service_id: "", type: "", price: "0", description: "", title: ""}
        },

        //single package
        selectSinglePackage(state, action) {
            state.selectedPackage = action.payload;
            state.isLoading = false;
        },
        getSignalPackages(state,action){
            state.signalPackages=action.payload;
        },
        getTrainingPackages(state,action){
            state.trainingPackages=action.payload;
        }

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getPackages,
    selectSinglePackage,
    getSignalPackages,
    getTrainingPackages,
    startLoading,
    hasError,
    success,
} = slice.actions;

// ----------------------------------------------------------------------

export function  pullPackages(user_id) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullPackagesApi(user_id);
            if(response.data.code === 200){
                const services = response.data.services;
                dispatch(slice.actions.getSignalPackages( services.filter((item)=>item.type ==="signal and setups"  && item.service_id !=="3e7ebd188fc948a5a4a6b8b399319d63"  )))
                dispatch(slice.actions.getTrainingPackages(services.filter((item)=>item.type!=="signal and setups")))
                dispatch(slice.actions.getPackages(services));
                dispatch(slice.actions.endLoading())
            }else{
                dispatch(slice.actions.hasError(response.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}

// ----------------------------------------------------------------------

export function  pullPackagesFree() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullPackagesFreeApi();
            if(response.data.code === 200){
                const services = response.data.services;
                dispatch(slice.actions.getSignalPackages( services.filter((item)=>item.type ==="signal")))
                dispatch(slice.actions.getTrainingPackages(services.filter((item)=>item.type!=="signal")))
                dispatch(slice.actions.getPackages(services));
                dispatch(slice.actions.endLoading())
            }else{
                dispatch(slice.actions.hasError(response.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}


// ----------------------------------------------------------------------

export function  pullSpecificPackages(user_id,packageId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await pullPackagesApi(user_id);
            if(response.data.code === 200){
                dispatch(slice.actions.getPackages(response.data.services));
                var jj =response.data.services.filter(item=>item.service_id === packageId)
                dispatch(slice.actions.selectSinglePackage(jj[0]));
            }else{
                dispatch(slice.actions.hasError(response.msg))
                dispatch(slice.actions.endLoading())
            }

        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.endLoading())

        }
    };
}


export function pullPackagesFree2(services) {
    dispatch(slice.actions.getSignalPackages( services.filter((item)=>item.type ==="signal and setups")))
    dispatch(slice.actions.getTrainingPackages(services.filter((item)=>item.type!=="signal and setups")))
    dispatch(slice.actions.getPackages(services));

}



