import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { mainUrl, pullPackagesApi, pulVideoCategoriesApi } from '../services';
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const url = mainUrl;

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          setSession(accessToken);
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;
          const response = await pullPackagesApi();
          console.log(response.data.code);

          if (response.data.code === 301) {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          } else {
            const user = { name: 'test user' };

            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const pullCategoriesHandler = async () => {
    try {
      const response = await pulVideoCategoriesApi();
      if (response.data.code === 200) {
        var cat = response.data.categories;
        var cat2 = [
          ...cat.filter(
            (item) =>
              item.category_id !== '00e2c4ac28b74451aad04b73a417990c' &&
              item.category_id !== '79303dbfaad04dcb90208eeb7fa40cb8' &&
              item.category_id !== 'a0ef53a5109648c1b3f304a477c03bf7' &&
              item.category_id !== 'ba595cdf8b25470284d6cce0468ff74f' &&
              item.category_id !== 'f9bf20969aea4c3184d18a4113314847'
          ),
        ];
        var cat2 = cat2.map((item, i) => ({
          path: `/dashboard/videos/${item.category_id}`,
          title: item.name,
        }));
        localStorage.setItem('categories', JSON.stringify(cat2));
      } else {
      }
    } catch (error) {}
  };

  const login = async (email, password) => {
    try {
      const data = await axios.post(mainUrl, {
        code: 101,
        api: 100,
        data: { email: email, password: password },
      });
      if (data.data.code === 200) {
        pullCategoriesHandler();
        const { user_id } = data.data.user_details;

        setSession(user_id);
        localStorage.setItem('userDetails', JSON.stringify(data.data.user_details));
        localStorage.setItem('instance', JSON.stringify(data.data.instance_id));
        localStorage.setItem('videoCategories', JSON.stringify(data.data.instance_id));
        dispatch({
          type: 'LOGIN',
          payload: {
            user: data.data.user_details,
          },
        });
      } else {
        console.error('Sukker');
      }

      return data;
    } catch (e) {
      console.log(e);
    }
    // axios
    //   .post('http://44.197.29.180:5000/api', {
    //     code: 101,
    //     api: 100,
    //     data: { email: email, password: password },
    //   })
    //   .then(function (response) {
    //     console.log(response.data.user_details);
    //     if (response.data.code === 200) {
    //       const { accessToken, user } = response.data.user_details;
    //       setSession(accessToken);
    //       dispatch({
    //         type: 'LOGIN',
    //         payload: {
    //           user,
    //         },
    //       });
    //       return response;
    //     } else {
    //       console.error('Sukker');
    //       return  response;
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const register = async (email, password, phoneNumber, fullName) => {
    try {
      const data = await axios.post(mainUrl, {
        code: 100,
        api: 100,
        data: {
          full_name: fullName,
          email: email,
          password: password,
        },
      });
      console.log(data);
      if (data.data.code === 200) {
        const { user_id } = data.data.user_details;

        // window.localStorage.setItem('accessToken', user_id);

        dispatch({
          type: 'REGISTER',
          payload: {
            user: data.data.user_details,
          },
        });
        window.location.replace(PATH_AUTH.login);
      }
      return data;
    } catch (e) {
      // console.log(error);
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
