import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

// position is by default absolute unless set otherwise

const LoadingComponent = ({position}) => (
    <div>
        <CircularProgress
            size={24}
            sx={{
                color:'main.primary',
                position: position ? position : 'absolute',
                top: '50%',
                left: '50%'
            }}
        />
    </div>
);

export default LoadingComponent;
