import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import Logo1 from '../assets/FX/logo.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 60, height: 60, ...sx }}>
      <img src={Logo1} alt="Logo Img" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  const thePath = window.location.href;
  var url = new URL(thePath);
  var packageId = url.searchParams.get('package');

  return <RouterLink to={`/auth/register?package=${packageId}`}>{logo}</RouterLink>;
}
