import React, { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Stack,
  Paper,
  Radio,
  Button,
  Collapse,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Autocomplete
} from '@mui/material';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
//
import PaymentNewCardForm from './PaymentNewCardForm';
import {LoadingButton} from "@mui/lab";

//icons
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import bitcoinIcon from '../../assets/FX/bitcoin.png'

// ----------------------------------------------------------------------

const PAYMENT_OPTIONS = [
  {
    value: 'mobile_money',
    title: 'Mobile Money',
    icons: ['https://imgkub.com/images/2022/03/10/Untitled-design-3.png'],
  },
  {
    value: 'credit_card',
    title: 'Credit / Debit Card',
    icons: [
      'https://minimal-assets-api.vercel.app/assets/icons/ic_mastercard.svg',
      'https://minimal-assets-api.vercel.app/assets/icons/ic_visa.svg',
    ],
  },
  {
    value: 'crypto',
    title: 'Crypto',
    icons: [
      bitcoinIcon
    ],
  },

];

const OptionStyle = styled(Paper)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2),
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

// ----------------------------------------------------------------------

export default function PaymentMethods({ handleChange,countryChange,countries,radioChange,onClickPayWithCrypto,loading}) {
  const [show, setShow] = useState(false);

  const [method, setMethod] = useState('');

  const handleCollapseIn = () => {
    if (method !== 'paypal') {
      setShow(!show);
    }
  };

  const handleCollapseOut = () => {
    setShow(false);
  };

  const handleChangeMethod = (event) => {
    document.getElementById("first_name").value=''
    document.getElementById("phone_number").value=''
    document.getElementById("phone_number2").value=''
    document.getElementById("last_name").value=''
    document.getElementById("country").value=''
    document.getElementById("first_name").value=''
    // document.getElementById("create-course-form").reset()
    if (method === 'paypal') {
      setShow(false);
    }
    radioChange(event.target.value)
    setMethod(event.target.value);
  };

  return (
    <div>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Payment Method
      </Typography>

      <RadioGroup value={method} onChange={handleChangeMethod}>
        <Stack spacing={3}>
          {PAYMENT_OPTIONS.map((option) => {
            const { value, title, icons } = option;

            const hasChildren = value === 'credit_card';

            const hasChild = value === 'mobile_money';

            const hasChildrenCrypto = value === 'crypto';

            const isSelected = method === value;

            return (
              <OptionStyle
                key={title}
                sx={{
                  ...(isSelected && {
                    boxShadow: (theme) => theme.customShadows.z20,
                  }),
                  ...(hasChildren && { flexWrap: 'wrap' }),
                  ...(hasChild && { flexWrap: 'wrap' }),
                  ...(hasChildrenCrypto && { flexWrap: 'wrap' }),
                }}
              >
                <FormControlLabel
                  value={value}
                  control={<Radio checkedIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />} />}
                  label={
                    <Typography variant="subtitle2" sx={{ ml: 1 }}>
                      {title}
                    </Typography>
                  }
                  sx={{ py: 3, mx: 0 }}
                />

                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{ position: 'absolute', right: 20, top: 32 }}
                >
                  {icons.map((icon) => (
                    <Image key={icon}  style={{size:"20px"}} alt="logo card" src={icon} />
                  ))}
                </Stack>

                {hasChild && (
                  <Collapse in={method === 'mobile_money'} sx={{ width: 1 }}>
                    <Stack spacing={3} mt={5} mb={5}>
                      <TextField  onChange={handleChange} type="number" id="phone_number" name="phone_number" fullWidth label="Phone Number" />
                    </Stack>

                    <Collapse in={show}>
                      <PaymentNewCardForm onCancel={handleCollapseOut} />
                    </Collapse>
                  </Collapse>
                )}

                {hasChildren && (
                  <Collapse in={method === 'credit_card'} sx={{ width: 1 }}>
                    <Stack spacing={3} mt={5} mb={5}>
                      <TextField onChange={handleChange}  id="first_name" name="first_name" fullWidth label="First name" />
                      <TextField onChange={handleChange} id="last_name" name="last_name" fullWidth label="Last name" />
                      <TextField onChange={handleChange}  type="number"  id="phone_number2" name="phone_number" fullWidth label="Phone number" />
                      <Autocomplete
                          id="country"
                          size="small"
                          style={{ marginTop: '37px', paddingBottom: '0px' }}
                          onChange={countryChange}
                          // getOptionSelected={handleBrandTypeAhead}
                          options={countries}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  // onChange={handleBrandTypeAhead}
                                  variant="outlined"
                                  label="Country"
                                  id="country"
                                  placeholder="country"
                                  required
                                  fullWidth
                              />
                          )}
                      />
                    </Stack>

                    <Collapse in={show}>
                      <PaymentNewCardForm onCancel={handleCollapseOut} />
                    </Collapse>
                  </Collapse>
                )}

                {hasChildrenCrypto && (
                    <Collapse in={method === 'crypto'} sx={{ width: 1 }}>
                      <Stack spacing={3} mt={5} mb={5}>

                      </Stack>

                    </Collapse>
                )}
              </OptionStyle>
            );
          })}
          {/*<LoadingButton loading={loading} onClick={onClickPayWithCrypto}  fullWidth size="large" type="submit" variant="contained" sx={{ mt: 5, mb: 3 }}>*/}
          {/*   <CurrencyBitcoinIcon style={{  backgroundColor:'green' ,borderRadius:'50%', margin:'10px' }} /> Pay with Crypto*/}
          {/*</LoadingButton>*/}
        </Stack>
      </RadioGroup>
    </div>
  );
}
